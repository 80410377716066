html,
body,
#root {
  min-height: 100%;
  height: 100%;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.App__ApiInput input {
  font-size: 15px !important;
  line-height: 20px !important;
  padding: 10px;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

.flex-4 {
  flex: 4;
}

.flex-5 {
  flex: 5;
}

.date-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  font-size: 20px;
  color: #777;
}

.time-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.time-wrapper>span {
  text-align: left;
  padding: 5px;
  font-size: 100px;
  color: #222;
}

.time-wrapper>span:first-child {
  text-align: right;
}

.keyboard-wrapper {
  display: flex;
  justify-content: center;
}

.keyboard {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  /* max-width: 50%; */
}

.keyboard>div {
  font-size: 25px;
  height: 80px;
  width: 80px;
  background-color: lightgrey;
  border: solid 1px grey;
  border-radius: 50%;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.keyboard>div:hover,
.keyboard>div:active {
  background-color: #777;
}

.input-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.input-wrapper>div {
  position: relative;
  min-width: 350px;
  min-height: 50px;
}

.input-wrapper>div>.icon {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40px;
}

.input-wrapper>div>.icon svg {
  padding: 10px;
}

.input-wrapper .input {
  border: solid 1px #ddd;
  font-size: 30px;
  line-height: 45px;
  width: 100%;
  height: 100%;
  min-height: 50px;
  text-align: center;
  padding: 5px
}

.action-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}


.response-wrapper {}

.response-wrapper .action-pane {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.response-wrapper .warning {
  background-color: gold;
  border: solid 1px goldenrod;
  border-radius: 10px;
  padding: 5px;
}

/*
  Components
*/
.btn {
  min-width: 150px;
  background-color: grey;
  display: inline-block;
  padding: 15px;
  font-size: 20px;
  cursor: pointer;

}

.btn.primary {
  background-color: green;
  color: white;
}

.btn.secondary {
  background-color: rosybrown;
}

.form-control {
  width: 100%;
}

input[type='text'].form-control {
  line-height: 50px;
  font-size: 40px;
  border: solid 1px #ddd;
  text-align: center;
}